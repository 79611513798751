// Reset css ----------------------------------------------------------------------------------------------
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}








// layout-mixin ----------------------------------------------------------------------------------------------
@mixin exposent()
{
	/* normalize afte mayeur reset sub/su styles
	* based on : https://gist.github.com/unruthless/413930
	*/
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}
@mixin block()
{
	position: relative;
	display: block;
}
@mixin inline-block()
{
	position: relative;
	display: inline-block;
}
@mixin absolute-block()
{
	position: absolute;
	display: block;
	float: none;
}
@mixin fixed-block()
{
	position: fixed;
	display: block;
	float: none;
}
@mixin table-block()
{
	position: relative;
	display: table;
}
@mixin left-block()
{
	@include block();
	float: left;
}
@mixin right-block()
{
	@include block();
	float: right;
}
@mixin break-block()
{
	@include block();
	clear: both;
	// @include clearfix();
}
@mixin auto-block()
{
	@include block();
	clear: both;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	float: none;
	// @include clearfix();
}
@mixin auto-abs-block()
{
	/* hack css margin auto in absolute *, %auto-abs-block{ /* hack css margin auto in absolute */
	@include absolute-block();
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
}
@mixin ninja-block()
{
	/* NIN NIN! */
	position: relative;
	display: block;
	height: 0rem;
	width: 0rem;
	visibility: hidden;
	overflow: hidden;
}
@mixin no-repeat-bg()
{
	background-position: 50% 50%;
	background-repeat: no-repeat;
}
@mixin full-bg()
{
	@include no-repeat-bg();
	background-size: cover;
}
@mixin cover-bg()
{
	@include full-bg();
}
@mixin contain-bg()
{
	@include no-repeat-bg();
	background-size: contain;
}
@mixin center-bg()
{
	@include no-repeat-bg();
	background-size: auto;
}
@mixin full-fixed-bg()
{
	@include full-bg();
	background-attachment: fixed;
}
@mixin container()
{
	@include auto-block();
	max-width: 134rem;
	width: 100%;
	padding-left: 2rem;
	padding-right: 2rem;
	overflow: hidden;
}
@mixin img-responsive()
{
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}
@mixin img-full()
{
	width: 100%;
	height: auto;
}
@mixin abstractElem()
{
	position: absolute;
	display: block;
	float: none;
	content: '';
}
@mixin abstract-element()
{
	position: absolute;
	display: block;
	float: none;
	content: '';
}
@mixin center()
{
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: 100%;
	text-align: center;
	>*{
		margin-left: auto;
		margin-right: auto;
	}
}
@mixin default-transition()
{
	 transition: opacity 0.5s;
}
@mixin all-transition()
{
	 transition: all 1s;
}
@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
	@content;
	}
	@-moz-keyframes #{$name} {
	@content;
	}
	@-ms-keyframes #{$name} {
	@content;
	}
	@keyframes #{$name} {
	@content;
	}
}
@include keyframes(no-anim) {

}

@mixin animation ( $name, $duration ) {
	@include animation-name( $name );
	@include animation-duration( $duration );
}
@include keyframes( fade ) {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@include keyframes( width-loading ) {
	0% {
		width: 0%;
	}
	100% {
		width: 100%;
	}
}
@include keyframes( height-loading ) {
	0% {
		height: 0%;
	}
	100% {
		height: 100%;
	}
}

// CSS de l'image à la une en fnd sur les pages
@mixin thumbnail{
	@extend .auto-block;
	width: 100vw;
	background-repeat:no-repeat;
	background-attachment:fixed;
	-o-background-size: cover;
	-moz-background-size: cover;
	-webkit-background-size:cover;
	background-size:cover;
	background-position: 50% 50%;
	z-index:5;
}

@mixin centered-wrapper() {
	@include block;
	width: 100%;
	max-width: 126rem;
	margin: auto;
	padding-left: 2rem;
	padding-right: 2rem;
}










// fonts ----------------------------------------------------------------------------------------------
/* ibm-plex-sans-200 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: url('../../assets/fonts/ibm-plex-sans-v7-latin-200.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans ExtraLight'), local('IBMPlexSans-ExtraLight'),
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-200.woff') format('woff'), /* Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-200.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-200italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  src: url('../../assets/fonts/ibm-plex-sans-v7-latin-200italic.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans ExtraLight Italic'), local('IBMPlexSans-ExtraLightItalic'),
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-200italic.woff') format('woff'), /* Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-200italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-300 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/ibm-plex-sans-v7-latin-300.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans Light'), local('IBMPlexSans-Light'),
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-300.woff') format('woff'), /* Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-300.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-300italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../../assets/fonts/ibm-plex-sans-v7-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans Light Italic'), local('IBMPlexSans-LightItalic'),
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-300italic.woff') format('woff'), /* Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-300italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-regular - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/ibm-plex-sans-v7-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans'), local('IBMPlexSans'),
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-regular.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../../assets/fonts/ibm-plex-sans-v7-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans Italic'), local('IBMPlexSans-Italic'),
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-italic.woff') format('woff'), /* Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-500 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/ibm-plex-sans-v7-latin-500.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans Medium'), local('IBMPlexSans-Medium'),
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-500.woff') format('woff'), /* Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-500.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-500italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: url('../../assets/fonts/ibm-plex-sans-v7-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans Medium Italic'), local('IBMPlexSans-MediumItalic'),
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-500italic.woff') format('woff'), /* Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-500italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-600 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../../assets/fonts/ibm-plex-sans-v7-latin-600.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans SemiBold'), local('IBMPlexSans-SemiBold'),
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-600.woff') format('woff'), /* Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-600.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-600italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: url('../../assets/fonts/ibm-plex-sans-v7-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans SemiBold Italic'), local('IBMPlexSans-SemiBoldItalic'),
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-600italic.woff') format('woff'), /* Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-600italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-700 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/ibm-plex-sans-v7-latin-700.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans Bold'), local('IBMPlexSans-Bold'),
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-700.woff') format('woff'), /* Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-700.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-700italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../../assets/fonts/ibm-plex-sans-v7-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans Bold Italic'), local('IBMPlexSans-BoldItalic'),
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-700italic.woff') format('woff'), /* Modern Browsers */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../../assets/fonts/ibm-plex-sans-v7-latin-700italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* iaeland */
@font-face {
	font-family: 'Aeland';
	src: url('../../assets/fonts/aeland-webfont.woff2') format('woff2'),
		 url('../../assets/fonts/aeland-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}







// var ----------------------------------------------------------------------------------------------
$remInit: 10px;
$baseFontSize: 1.6rem;

$IBMPlex: 'IBM Plex Sans', sans-serif;
$Aeland: 'Aeland', sans-serif;
$mainFont: $IBMPlex;
$titleFont: $IBMPlex;
$specificFont: $Aeland;

$bodyBackground: white;

$brand-primary: #ffffff;
$brand-secondary: #24337a;
$brand-tertiary: #d23685;
$lightGrey: #e6e6e6;







// layout ----------------------------------------------------------------------------------------------
*{
	font-family: $mainFont;
	box-sizing: border-box;
}
html{
	font-family: $mainFont;
	overflow-x: hidden;
	height: 100%;
	width: 100%;
	font-size: $remInit; /* INIT REM DON'T TOUCH! if you want to change font size do it in body tag plz */
}
body{
	height: auto;
	width: 100%;
	font-family: $mainFont;
	font-size: $baseFontSize;
	background-color: $bodyBackground;
}
%exposent {
	@include exposent();
}
sup, %sup {
	@extend %exposent;
	top: -0.5em;
}
sub, %sub {
	@extend %exposent;
	bottom: -0.25em;
}
i, %i, %italic{
	font-style: italic;
}
b, strong{
	font-weight: bold;
}
u{
	text-decoration: underline;
}
a, %link, %a{
	text-decoration: none;
	&:hover{
		text-decoration: none;
	}
}

.block-content .content a:not([class^='btn']){
	font-size:1.5rem;
	font-family:$mainFont;
	&:hover{
		text-decoration:underline;
	}
	&:visited{
		text-decoration:underline;
	}
	&:active{
		text-decoration:underline;
	}
	&:focus{
		text-decoration:underline;
	}
}

.both, .clear, .clearboth, %both, %clear, %clearboth{
	clear: both;
	// @include clearfix();
}
#page, #main-container, %page{
	@include auto-block();
	width: 100%;
	max-width: 300rem;
	min-width: 32rem;

	overflow: hidden;
}
%title{
	font-family: $titleFont;
}
h1, h2, h3, h4, h5, h6, .title-strong, %title-strong, .title{
	@extend %title;
}
%btn{
	@include block();
	font-family: $titleFont;
}
.btn, .btn-silver, .btn-red, button, input[type="submit"]{
	@extend %btn;
}
.default-transition, %default-transition{
	@include default-transition()
}
.all-transition, %all-transition{
	@include all-transition()
}
.color-transition, %color-transition{
	transition: color 0.5s, background-color 0.5s;
}
.site-main, %site-main{
	padding: 0;
}
.entry-footer, .comments-area, .mobil-only, .post-navigation, %entry-footer, %comments-area, %mobil-only, %post-navigation{
	display: none!important;
}
.lowercase, %lowercase{
	text-transform: lowercase!important;
}
.uppercase, %uppercase{
	text-transform: uppercase!important;
}
.capitalize, %capitalize{
	text-transform: capitalize!important;
}
small, .small, %small{
	font-size: 1.3rem;
}
.align-left, %align-left{
	text-align: left;
}
.align-right, %align-right{
	text-align: right;
}
.align-center, %align-center{
	text-align: center;
}
.strong, %strong{
	font-weight:bold !important;
}
.content{
	@extend %content !optional;
}
*[class^='content-']{
	@extend .content;
}
.exposent{
	@include exposent();
}
.block{
	@include block();
}
.absolute-block,{
	@include absolute-block();
}
.fixed-block{
	@include fixed-block();
}
.table-block{
	@include table-block();
}
.left-block{
	@include left-block();
}
.right-block{
	@include right-block();
}
.break-block{
	@include break-block();
}
.auto-block{
	@include auto-block();
}
.auto-abs-block{
	@include auto-abs-block();
}
.ninja-block{
	@include ninja-block();
}
.no-repeat-bg{
	@include no-repeat-bg();
}
.full-bg{
	@include full-bg();
}
.center-bg{
	@include center-bg();
}
.full-fixed-bg{
	@include full-fixed-bg();
}
.container{
	@include container();
}
.img-responsive{
	@include img-responsive();
}
.img-full{
	@include img-full();
}
.abstractElem{
	@include abstractElem();
}
.center{
	@include center();
}
.container,*[class^='container-']{
	@include container();
}
img, %img, %image{
	max-width: 100%;
	max-height: 100%;
}

%input{
	max-width: 100%;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
	@extend %input;
}







// template ----------------------------------------------------------------------------------------------
::-webkit-selection {
	background: $brand-secondary;
	color: white;
}
::-moz-selection {
	background: $brand-secondary;
	color: white;
}
::-ms-selection {
	background: $brand-secondary;
	color: white;
}
::-o-selection {
	background: $brand-secondary;
	color: white;
}
::selection {
	background: $brand-secondary;
	color: white;
}

body {
	color: $brand-secondary;
	font-family: $mainFont;
	background-color: white;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin-top: 0;
	color: inherit;
	font-weight: 500;
	line-height: 1.1;
	font-family: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
}


.clearfix::after {
	content: " ";
	display: block;
	height: 0;
	clear: both;
}


button,
input[type="button"],
input[type="submit"] {
	padding: 0;
	border: none;
	box-shadow: none;
	border-radius: 0;
	text-shadow: none;
	background-color: transparent;

	&:hover, &:active, &:focus {
		box-shadow: none;
		text-decoration: none;
	}
}


ul, ol {
	margin: 0;
	list-style: none;

	li {
		list-style: inside;
	}
}

ul {
	padding-left: 0;
	margin-bottom: 1rem;
}

p + ul {
	margin-top: 1rem;
}

h1, ul, li, p, a {
	line-height: 1.3;
	font-size: 1.8rem;
	color: $brand-secondary;
}

p {
	margin: 0;
	font-size: 1.3rem;
	color: $brand-secondary;

	span {
		font-size: 2rem;
		color: $brand-tertiary;
		font-family: $specificFont;
	}
}

h1 {
	font-family: $mainFont;
}

h2 {
	&, & a {
		font-size: 4rem;
		line-height: 1.3;
		margin-bottom: 7rem;
		color: $brand-primary;
	}

	&:after {
		content: "";
		display: block;
		width: 5.5rem;
		height: .4rem;
		margin-top: 2.3rem;
		background-color: $brand-primary;
	}
}

a {
	color: $brand-primary;
	transition: background-color .3s ease;

	&:hover, &:active, &:focus {
		color: $brand-tertiary;
	}

	&.btn {
		display: inline-block;
		font-size: 2rem;
		text-decoration: none;
		font-family: $mainFont;
		color: $brand-secondary;
		background-color: black;
		text-transform: uppercase;
		padding: 1rem 2.5rem 1.2rem;

		span {
			margin-left: 1rem;
		}

		&:hover, &:active, &:focus {
			text-decoration: none;
			color: $brand-secondary;
			background-color: $brand-primary;
		}
	}
}

p + .btn {
	margin-top: 4.5rem;
}

p + p {
	margin-top: 2rem;
}

p + h2,
p + h3,
p + h4,
p + h5 {
	margin-top: 5rem;
}

#root {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 48rem;
	height: 100vh;
}

.content {
	@include block;
	height: 100%;
	padding-top: 9.6rem;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center bottom;
	background-image: url('../../assets/images/background.jpg');

	&.bg-white {
		background-image: url('../../assets/images/background.jpg');
	}

	&.bg-pink {
		background-image: url('../../assets/images/background-pink.jpg');
	}

	&.bg-blue {
		background-image: url('../../assets/images/background-blue.jpg');
	}
}

.bloc-title,
.entry-title,
.page-title {
	margin: 0;
	line-height: 1;
	font-size: 3.9rem;
	font-weight: bold;
	margin-top: 3.5rem;
	margin-bottom: 4rem;
	color: $brand-primary;
}


// header -----------------------------------------------
.main-header {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	height: 9.8rem;
	width: 100%;
	z-index: 9999;

	.header-logo {
		position: relative;
		display: inline-block;
		width: 100%;
		height: 9.8rem;
		overflow: hidden;

		img {
			position: absolute;
			top: 0;
			left: 50%;
			height: 9.8rem;
			width: auto;
			max-width: inherit;
			transform: translateX(-50%);

			&.header-femme,
			&.header-homme {
				display: none;
			}
		}
	}
}

.femme .main-header .header-logo img.header-femme {
	display: block;
}
.femme .main-header .header-logo img.header-classic,
.femme .main-header .header-logo img.header-homme {
	display: none;
}

.homme .main-header .header-logo img.header-homme {
	display: block;
}
.homme .main-header .header-logo img.header-classic,
.homme .main-header .header-logo img.header-femme {
	display: none;
}


// footer -----------------------------------------------
.main-footer {
	@include block
	width: 100%;
	z-index: 9999;
	font-size: 1.1rem;
	text-align: center;

	p, a {
		font-size: 1.5rem;
		color: $brand-primary;
	}

	a {
		color: $brand-primary;
		text-decoration: underline;

		&:hover, &:active, &:focus {
			color: $brand-tertiary;
		}
	}

	.footer-links {
		@include block;
		font-size: 1rem;
		text-align: center;
		padding-top: 0.3rem;
		padding-bottom: 0.3rem;
		color: $brand-primary;
		background-color: $brand-secondary;

		p, a {
			font-size: 1rem;
		}

		a {
			color: $brand-primary;

			&:hover, &:active, &:focus {
				color: $brand-primary;
			}
		}
	}
}


// cookieBanner -----------------------------------------------
.cookie-banner {
	position: fixed;
	left: 2rem;
	bottom: 2.6rem;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 35rem;
	width: calc(100% - 4rem);
	z-index: 9999;
	padding: 4rem 1.5rem;
	text-align: center;
	background-color: $brand-primary;
	box-shadow: 0 0 .8rem .1rem rgba(0,0,0,0.1);

	img {
		display: block;
		width: 7rem;
		height: auto;
		margin: auto;
	}

	p {
		font-size: 1.5rem;
		color: $brand-secondary;
		margin: 3rem 0;
	}

	button {
		margin: auto;
		cursor: pointer;
		font-size: 1.4rem;
		font-style: italic;
		border-radius: 0.3rem;
		color: $brand-primary;
		padding: 1.1rem 4.5rem;
		background-color: $brand-secondary;

		&:hover, &:active, &:focus {
			background-color: lighten($brand-secondary, 8%);
		}
	}
}

// home -----------------------------------------------
.main-wrapper {
	@include block;
	width: 100%;
	height: 100%;
	overflow: hidden;
	animation: fadein 1s;

	@keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
}

.main-choices {
	@include block;
	max-width: 28rem;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: auto;
}

.main-choice {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 100%;
	height: 8rem;
	cursor: pointer;
	text-align: left;
	overflow-y: hidden;
	border-radius: 1rem;
	margin-bottom: .7rem;
	border-radius: .8rem;
	transition: all .2s ease;
	background-color: $brand-primary;
	box-shadow: 0 0 .8rem .1rem rgba(0,0,0,0.1);

	&:hover, &:active, &:focus {
		transform: translateX(0.5rem);
		box-shadow: 0 .4rem 0.8rem 0.1rem rgba(0, 0, 0, 0.1);

		.choice-img {
			background-color: lighten($brand-secondary, 6%);
		}
	}
}

.choice-img,
.choice-infos {
	@include block;
}

.choice-img {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 8.6rem;
	text-align: center;
	transition: all .2s ease;
	background-color: $brand-secondary;

	img {
		width: 7.5rem;
		height: 7.5rem;
		margin: auto;
	}
}

.choice-infos {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: auto;
	padding: 1rem 1.8rem;

	p {
		line-height: 1.2;
		font-size: 1.3rem;
	}

	span {
		display: block;
	}
}


// Button One + Button Two -----------------------------------------------
.main-tabs {
	display: block;
	max-width: 28rem;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: auto;
	overflow: hidden;
	padding-bottom: 8rem;

	.title {
		line-height: 1.1;
		font-size: 1.4rem;
		text-align: center;
		margin-bottom: 1.5rem;
		color: $brand-secondary;

		span {
			display: block;
			font-size: 2rem;
			color: $brand-tertiary;
			font-family: $specificFont;
		}
	}

	div {
		&:focus {
			outline: none;
		}
	}
}

.main-tabs-inner {
	@include block;
	overflow: hidden;
	max-height: 28.5rem;
}

.main-tab {
	@include block;
	max-width: 28rem;
	width: 100%;
	height: 23.5rem;
	overflow: hidden;
	border-radius: 1rem;
	margin-bottom: 7.4rem;
	padding: 1.5rem 1.8rem 1.5rem;
	background-color: $brand-primary;

	.rec-carousel-wrapper {
		height: 21.4rem!important;
	}

	.sc-AxhUy {
		height: 100%!important;
	}
	.rec-item-wrapper {
		overflow: visible;
	}
	.rec-slider-container {
		margin: 0;
	}
	.rec-swipable-null {
		height: 100%;
	}
	.rec-pagination {
		margin-top: 0;
	}
	.rec-dot {
		@include inline-block;
		width: 0.9rem;
		height: 0.9rem;
		cursor: pointer;
		box-shadow: none;
		border-radius: 50%;
		margin-left: .8rem;
		margin-right: .8rem;
		background-color: $lightGrey;
	}
	.rec-dot_active {
		box-shadow: none;
		background-color: $brand-secondary;

		&:active, &:hover, &:focus {
			color: $brand-secondary;
			box-shadow: none;
		}
	}
}

.main-tab-inner {
	position: relative;
	width: 100%;

}

.main-tabs-2 {
	.main-tab-1,
	.main-tab-2 {
		.tab-title p {
			padding-top: 0.8rem;
		}
	}
	.main-tab-3 {
		text-align: center;

		.tab-title {
			display: none;
		}

		.tab-infos {
			p {
				margin-top: 6.5rem;
			}
			a {
				display: block;
				font-size: 3rem;
				font-weight: bold;
				margin-top: 0.5rem;
				color: $brand-tertiary;
			}
		}
	}
}

.tab-title {
	@include block;
	min-height: 3.5rem;
	margin-bottom: 1.2rem;
	padding-right: 5rem;

	p {
		line-height: 1.1;
		font-weight: bold;
		font-size: 1.8rem;
		color: $brand-tertiary;
	}
	span {
		position: absolute;
		right: 0.8rem;
		top: -0.4rem;
		font-size: 4.7rem;
		font-weight: bold;
		color: $brand-tertiary;
	}
}

.tab-infos {
	@include block;
	width: 100%;

	p {
		line-height: 1.1;
		font-size: 1.3rem;
	}

	small {
		display: block;
		font-size: 0.9rem;
	}

	b {
		display: block;
		font-size: 1.5rem;
		font-weight: bold;
	}
}

.tab-nav {
	position: absolute;
	bottom: 1rem;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;

	.tab-nav-circle {
		@include inline-block;
		width: 0.9rem;
		height: 0.9rem;
		cursor: pointer;
		border-radius: 50%;
		background-color: $lightGrey;

		&:nth-of-type(2) {
			margin: 0 1.5rem;
		}

		&:active, &:focus, &:hover, &.active {
			background-color: $brand-secondary;
		}
	}
}


.echantillon {
	position: absolute;
	bottom: -.1rem;
	left: 0;
	display: block;
	width: 100%;
	text-align: center;
	padding-bottom: 0.7rem;

	a {
		@include block;
		line-height: 0.8;
		font-size: 1.3rem;
		z-index: 2;
		color: $brand-secondary;
	}

	span {
		display: block;
		font-size: 1.8rem;
		color: $brand-tertiary;
		font-family: $specificFont;
	}

	img {
		position: absolute;
		bottom: 0.1rem;
		left: 50%;
		width: auto;
		height: 7.7rem;
		max-width: inherit;
		max-height: inherit;
		z-index: 1;
		transform: translateX(-50%);
	}

	&:before {
		content: " ";
		position: absolute;
		bottom: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 1.8rem;
		background-color: $brand-primary;
	}
}


// Button Four -----------------------------------------------
.main-forms {
	@include block;
	max-width: 28rem;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: auto;

	.title {
		height: 4.4rem;
		font-size: 2rem;
		line-height: 1.1;
		text-align: center;
		font-style: italic;
		margin-bottom: 1.1rem;
		color: $brand-secondary;
	}

	&.main-forms-1 {
		.title {
			padding-top: 1.8rem;
		}
	}
}

.main-forms-inner {
	position: relative;
	display: block;
	max-height: 32.5rem;
}

.main-form {
	@include block;
	max-width: 28rem;
	width: 100%;
	height: 23.5rem;
	padding: 1.2rem 0 0;
	border-radius: 1rem;
}

.form-column {
	@include block;
	width: 4.5rem;
	height: 28rem;
	float: left;
	padding-top: 1.8rem;

	&:before {
		content: " ";
		position: absolute;
		top: -2.5rem;
		left: 0.8rem;
		display: block;
		width: .2rem;
		height: 29rem;
		background-color: $brand-secondary;
	}

	.bulle {
		@include block;
		width: 1.8rem;
		height: 1.8rem;
		text-align: center;
		padding-top: .4rem;
		border-radius: 50%;
		margin-bottom: 4.9rem;
		background-color: $brand-secondary;

		img {
			display: block;
			width: 1.2rem;
			height: auto;
			display: none;
			margin: auto;
		}

		&.active {
			img {
				display: block;
			}
		}
	}
}

.form-inputs {
	@include block;
	width: 19rem;
	float: left;
}

.form-input {
	@include block;
	height: 5rem;
	width: 100%;
	cursor: pointer;
	overflow: hidden;
	text-align: center;
	font-size: 1.4rem;
	border-radius: 1rem;
	border-radius: .8rem;
	margin-bottom: 1.8rem;
	color: $brand-secondary;
	transition: all .2s ease;
	background-color: $brand-primary;
	box-shadow: 0 0 .8rem .1rem rgba(0,0,0,0.1);

	&:hover, &:active, &:focus {
		box-shadow: 0 .4rem 0.8rem 0.1rem rgba(0, 0, 0, 0.1);
	}

	&.single-line-input {
		line-height: 4.8rem;

		&:hover, &:active, &:focus {
			color: $brand-tertiary;
		}
	}

	&.multiple-line-input {
		font-size: 1.2rem;

		b {
			display: block;
			font-size: 1.4rem;
			padding-top: .9rem;
			margin-bottom: .2rem;
		}

		img {
			display: inline-block;
			width: 1.3rem;
			height: auto;
			margin: .8rem .1rem .3rem;
		}

		span {
			display: block;
			font-size: 1.2rem;
			color: $brand-secondary;
		}
	}
}


.bg-pink {
	.title {
		color: $brand-primary;
	}
}

.bg-blue {
	.title {
		color: $brand-primary;
	}

	.form-column {
		@include block;
		width: 4rem;
		height: 29rem;
		float: left;

		&:before {
			background-color: $brand-primary;
		}

		.bulle {
			background-color: $brand-primary;
		}
	}

	.main-forms-echantillon {
		.form-btn {
			left: 5rem;
			width: 9.5rem;
		}
		.form-btn-left {
			width: 100%;
		}
	}
}

.main-forms-1-1,
.main-forms-1-2,
.main-forms-1-3,
.main-forms-1-4,
.main-forms-echantillon {
	.main-form {
		height: 28.5rem;
		padding: 1.2rem 0 0;
		margin-bottom: 0rem;
	}
}

.main-forms-1-1,
.main-forms-1-2 {
	&:hover, &:active, &:focus {
		.form-input {
			background-color: rgba($brand-primary, 0.8);
			&:hover, &:active, &:focus {
				color: $brand-secondary;
				background-color: rgba($brand-primary, 1);
			}
		}
	}
}

.main-forms-echantillon {
	.title {
		width: 19rem;
		text-align: left;
		margin-left: auto;
		margin-right: auto;
	}

	.form-inputs {
		width: 23rem;
		height: 100%;
	}

	.form-info {
		padding-top: .4rem;

		p {
			font-size: 1.4rem;
			color: $brand-primary;
		}
	}

	.form-btn {
		position: absolute;
		bottom: 1.5rem;
		width: 19rem;
		margin-left: 0;
		cursor: pointer;

		&:hover, &:active, &:focus {
			span {
				color: $brand-primary;
				background-color: $brand-tertiary;
			}
		}
	}

	.form-btn-right,
	.form-btn-left {
		@include block;
		width: 50%;
		height: 9rem;
		float: left;
	}

	.form-btn-right {
		text-align: center;
		background-color: $brand-primary;

		img {
			display: block;
			width: 8rem;
			height: auto;
			margin: .5rem auto 0;
		}
	}

	.form-btn-left {
		padding: 1.3rem 1rem;
		background-color: $brand-secondary;

		p {
			color: $brand-primary;
			margin-bottom: 0.6rem;
		}

		span {
			font-weight: bold;
			font-size: 1.2rem;
			text-align: center;
			border-radius: 1rem;
			padding: 0.1rem .5rem;
			color: $brand-secondary;
			background-color: $brand-primary;
		}
	}
}



.redirection {
	text-align: center;

	.title {
		width: 100%;
		text-align: center;
		font-size: 1.4rem;
	}
	p {
		font-size: 2.3rem;
		font-weight: bold;
		margin-bottom: 2rem;
	}
}




@media(min-width: 767px) {
	#root {
		min-height: 60rem;
	}


	.CookieConsent img {
		width: 10rem;
	}
	.CookieConsent button {
		font-size: 1.6rem;
	}


	.main-choices {
		max-width: 36rem;
	}
	.main-choice {
		height: 9rem;
	}
	.choice-img {
		max-width: 9.6rem;
	}
	.choice-infos p {
		line-height: 1.3;
		font-size: 1.4rem;
	}
	.choice-infos p span {
		font-size: 2.1rem;
	}


	.main-tabs {
		max-width: 36rem;
		.rec-carousel-wrapper {
			height: 23rem!important;
		}
	}
	.main-tabs .title {
		font-size: 1.7rem;
	}
	.main-tabs .title {
		margin-bottom: 2rem;
	}
	.main-tabs .title span {
		font-size: 2.4rem;
	}
	.main-tab {
		height: 26.5rem;
		max-width: 100%;
	}
	.main-tabs-inner {
		max-height: 32.5rem;
	}
	.tab-title {
		margin-bottom: 2rem;
	}
	.tab-title p {
		font-size: 2.1rem;
	}
	.tab-infos p {
		font-size: 1.6rem;
	}
	.tab-infos b {
		font-size: 1.8rem;
	}
	.tab-infos small {
		font-size: 1rem;
		margin-top: .3rem;
	}


	.main-forms {
		max-width: 36rem;
	}
	.main-form {
		max-width: 100%;
		height: 31rem;
	}
	.main-forms-inner {
		max-height: 36.5rem;
	}
	.form-inputs {
		width: 27rem;
	}
	.form-input,
	.form-input.multiple-line-input b {
		font-size: 1.6rem;
	}
	.form-input {
		height: 5.6rem;
	}
	.main-forms-echantillon .main-form {
		height: 30.5rem;
		padding: 1.2rem 0 0;
		margin-bottom: 0rem;
	}
	.form-input.multiple-line-input,
	.form-input.multiple-line-input span {
		font-size: 1.4rem;
	}
	.form-input.single-line-input {
		line-height: 5.4rem;
	}
	.form-column {
		height: 32rem;
	}
	.form-column .bulle {
		margin-bottom: 6rem;
	}
	.form-column::before {
		height: 33rem;
	}
	.form-input.multiple-line-input img {
		margin: 1.1rem .3rem 0;
	}
	.main-forms-echantillon .title,
	.main-forms-echantillon .form-inputs {
		width: 27rem;
	}
	.main-forms .title {
		font-size: 2.3rem;
	}
	.main-forms-echantillon .form-info p {
		font-size: 1.6rem;
	}
	.main-forms-echantillon .form-btn {
		font-size: 2rem;
	}
	.bg-blue .main-forms-echantillon .form-btn {
		left: 8rem;
		width: 11.5rem;
	}
	.bg-blue .main-forms-echantillon .form-btn-left {
		height: 10rem;
		text-align: center;
	}
	.main-forms-echantillon .form-btn-left p {
		font-size: 1.4rem;
	}
	.main-forms-echantillon .form-btn-left span {
		font-size: 1.4rem;
	}
	.main-forms-echantillon .form-btn {
		width: 21rem;
	}

	.redirection {
		.title {
			width: 100%;
			height: auto;
			font-size: 1.8rem;
			line-height: 1.2;
		}
		p {
			font-size: 2.6rem;
		}
	}
}
